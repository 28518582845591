html,
body {
  margin: 0;
  padding: 0;
}
/* 
body {
  font-size: 16px;
  color: #333;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #ffffff;
} */

.body-wrapper,
.body.ntnx-flex-item.ntnx {
  z-index: unset !important;
}

.frame-logo {
  width: 300px;
}

.section-borderless-content {
  background-color: #f2f4f6;
}

.session-components {
  width: 100%;
}

.header {
  font-size: 1.5rem;
  color: #555;
  margin: 0 0 1rem 0;
}

.controls {
  list-style: none;
  padding-left: 0;
  margin: 0 1em;
  border: 1px rgba(0, 90, 128, 0.3) solid;
  box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.1), 0px 5px 5px rgba(0, 0, 0, 0.1);
  padding: 2em;
}

.controls li {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  border-bottom: 1px solid grey;
}

.controls > li > label {
  flex: 1 0 100px;
  max-width: 200px;
  font-weight: bold;
}

.controls > li > label + * {
  flex: 1 0 200px;
}

.controls > li > input {
  max-width: 200px !important;
}

.controls input,
.controls textarea {
  border-radius: 3px;
  display: inline-block;
  padding: 0.5em 0;
  font-size: 12px;
  width: 100%;
  margin: 0;
  border: 1px rgba(0, 0, 0, 0.1) solid;
  min-width: 550px;
}

.controls li button {
  padding: 1em;
  border-radius: 3px;
}

.controls button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.ntnx.ntnx-main-page-layout[data-full-page="true"] {
  z-index: unset !important;
  overflow: auto;
}

.tutorial-controls {
  position: fixed;
  bottom: 45%;
  right: 25px;
  width: 150px;
  height: 150px;
  z-index: 100000;
  display: flex;
  justify-content: space-around;
  flex-direction: column-reverse;
  align-items: center;
}

.tutorial-controls button {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  opacity: 0.25;
  transition: opacity 0.2s ease-in;
  /* text-transform: uppercase; */
  font-weight: bold;
  letter-spacing: -1px;
  outline: none;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.29);
  border: solid 4px rgb(87, 87, 87);
  font-size: 12px;
  color: #292929;
  background: rgba(255, 255, 255, 0.913);
  cursor: pointer !important;
}

.tutorial-controls button:hover {
  opacity: 1;
}

/* .tutorial-controls button:active {
  -ms-transform: translateY(2px);
  -webkit-transform: translateY(2px);
  transform: translateY(2px);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.15);
  border-bottom: none;
} */

.js-highlight,
.js-highlight > pre {
  flex-grow: 1;
  display: flex;
}

.js-highlight > pre code {
  width: 100% !important;
  border-radius: 3px;
}

#session-status {
  font-style: italic;
}

ul#logs {
  font-family: "Courier New", Courier, monospace;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  justify-content: flex-start;
  min-height: -webkit-min-content;
}

#logs li {
  display: flex;
  padding: 3px;
  font-size: 14px;
  border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
}

#logs li:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

#logs li.error {
  background-color: rgba(255, 0, 0, 1);
  color: white;
  font-weight: bold;
}

li.error:hover {
  background-color: rgb(160, 0, 0) !important;
}

.footer {
  font-size: 1rem;
  padding: 2em;
  align-items: center;
}

[data-selector="frame-terminal-container"] {
  /* display: relative !important; */
  z-index: 200 !important;
  transform: scale3d(0.8, 0.8, 1) translate(calc(105% - 30px)) !important;
  transition: 0.5s all cubic-bezier(0.175, 0.885, 0.32, 1.275) !important;
  right: 0 !important;
  box-shadow: -10px 0px 30px rgba(0, 0, 0, 0.5);
}

[data-selector="frame-terminal-container"].active-session {
  transform: scale3d(1, 1, 1) translate(calc(0%)) !important;
}
